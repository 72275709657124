/*!
 * LiteWebChat_Frame v2.1.0
 * MorFans Lab(c) 2017
 *
 * BY:SuperPaxxs & Haswikr
 * https://lab.morfans.cn
 *
 * Released under the LGPL License
 */

/* 主 */
.lite-chatbox {
  position: relative;
  width: 100%;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 18px;
  font-family: Helvetica, 'PingFang SC', 'Microsoft YaHei', sans-serif;
}

/* 聊天区域 */
.lite-chatbox .cmsg {
  position: relative;
  min-height: 50px;
  margin: 4px 7px;
  border: 0;
}

.lite-chatbox .cright {
  margin-left: 64px;
  text-align: right;
}

.lite-chatbox .cleft {
  margin-right: 64px;
  text-align: left;
}

/* 聊天组件：全局 */

/* 头像 */

/* .lite-chatbox img.headIcon { */
.lite-chatbox .headIcon {
  position: absolute;
  top: 9px;
  width: 34px;
  height: 34px;
  border: 1px solid #c5d4c4;
}

/* 昵称 */
.lite-chatbox .name {
  display: block;
  color: #8b8b8b;
  font-size: 12px;
  line-height: 18px;
}

.lite-chatbox .name .htitle {
  display: inline-block;
  max-width: 50px;
  margin-right: 4px;
  padding: 0 3px 0 3px;
  overflow: hidden;
  color: #fff;
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  background-color: #ccc;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

/* 消息内容 */
.lite-chatbox .content {
  position: relative;
  display: inline-block;

  /* -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px; */

  /* 空白消息 */
  min-width: 9px;
  min-height: 18px;
  padding: 10px 15px;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-line;
  text-align: left;
  word-wrap: break-word;
  word-break: break-all;
}

/* 消息内容图片处理 */
.lite-chatbox .content img {
  width: 100%;
  height: auto;
}

/* 消息内容超链接处理 */
.lite-chatbox .content a {
  margin: 0 5px;
  color: #0072c1;
  cursor: hand;
}

/* 聊天提示条 */
.lite-chatbox .tips {
  margin: 12px;
  font-size: 12px;
  text-align: center;
}

/* 聊天提示条文本 */
.lite-chatbox .tips span {
  display: inline-block;
  padding: 4px;
  color: #fff;
  background-color: #ccc;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

/* 聊天组件：微调 */

/* 圆头像 */
.lite-chatbox img.radius {
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}

/* 左右头像 */

/* .lite-chatbox .cright ÷mg.headIcon { */
.lite-chatbox .cright .headIcon {
  right: 0;
}

/* .lite-chatbox .cleft img.headIcon { */
.lite-chatbox .cleft .headIcon {
  left: 0;
}

/* 左右昵称 */
.lite-chatbox .cright .name {
  margin: 0 48px 2px 0;
}

.lite-chatbox .cleft .name {
  margin: 0 0 2px 48px;
}

/* 左右聊天气泡 */
.lite-chatbox .cright .content {
  margin: 0 48px 0 0;
  color: white;

  /* border: 1px solid #78cdf8; */
  background: -webkit-linear-gradient(70deg, #3fd1e1 0%, #44d7cd 100%);
  background: linear-gradient(20deg, #3f8fe1cc 0%, #44d7c9 100%);
  -webkit-border-radius: 20px 0 20px 20px;
  border-radius: 20px 0 20px 20px;
  -webkit-box-shadow: 5px 5px 15px 0 rgba(102, 102, 102, 0.15);
  box-shadow: 5px 5px 15px 0 rgba(102, 102, 102, 0.15);
}

.lite-chatbox .cleft .content {
  margin: 0 0 0 48px;
  color: #666;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 0 20px 20px 20px;
  border-radius: 0 20px 20px 20px;
  -webkit-box-shadow: 5px 5px 15px 0 rgba(102, 102, 102, 0.1);
  box-shadow: 5px 5px 15px 0 rgba(102, 102, 102, 0.1);
}

.lite-chatbox .cright .content::after {
  top: 8px;
  right: -12px;
}

.lite-chatbox .cleft .content::after {
  top: 8px;
  left: -12px;
}

/* 聊天提示条附加样式 */
.lite-chatbox .tips .tips-primary {
  background-color: #3986c8;
}

.lite-chatbox .tips .tips-success {
  background-color: #49b649;
}

.lite-chatbox .tips .tips-info {
  background-color: #5bb6d1;
}

.lite-chatbox .tips .tips-warning {
  background-color: #eea948;
}

.lite-chatbox .tips .tips-danger {
  background-color: #e24d48;
}

.lite-chatbox .name .admin {
  background-color: #72d6a0;
}

.lite-chatbox .name .owner {
  background-color: #f2bf25;
}
