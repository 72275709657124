.customer-service-chat {
  position: fixed;
  top: 56px;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 30px;
  background-color: #f7f6f6;
  border-radius: 25px;
  cursor: pointer;

  .title {
    font-weight: bold;
    font-size: 16px;
  }

  .no-read {
    margin: 0 5px 0 30px;
  }

  .read-all {
    margin-left: 40px;
  }
}
