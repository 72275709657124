.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 4px 8px;
  font-size: 12px;
  background-color: #fafafa;
}

.row-dragging .drag-visible {
  visibility: visible;
}
