.rich-editor {
  .bf-content {
    height: 420px;
  }
}

.form-item-suffix {
  position: absolute !important;
  top: 0;
  right: 0;
  transform: translateX(100%);
  &.suffix-label {
    height: 32px;
    padding: 4px 15px;
  }
}
