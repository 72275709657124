.chat-item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  &.selected {
    background-color: #e9e9e9;
  }

  .ant-badge {
    flex-shrink: 0;
  }

  .message-name {
    flex-grow: 1;
    margin: 0 8px;
    overflow: hidden;

    .name,
    .message {
      overflow: hidden;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .name {
      font-weight: bold;
    }
  }

  .date-wrap {
    width: 65px;
  }

  .message-time {
    // width: 65px;
    width: 80px;
    font-size: 12px;
    text-align: center;
    zoom: 0.85;

    &.undo {
      line-height: 1;
    }
  }

  .ant-ribbon-wrapper {
    width: 55px;
    height: 16px;
  }

  .ant-ribbon.un-do {
    top: -8px;
    right: -23px;
    transform: scale(0.8);
  }
}
