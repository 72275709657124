.cash-out-modal {
  .content-top {
    margin-bottom: 20px;
    font-weight: bold;
  }

  .copy-tips {
    margin-bottom: 10px;
  }

  .copy-item {
    display: flex;
    align-items: center;
    width: 350px;

    span:nth-child(2) {
      flex-grow: 1;
    }
  }
}
