.main-layout {
  height: 100vh;

  .header {
    display: flex;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;

    .ant-menu-title-content {
      position: relative;

      .ant-badge {
        position: absolute;
        top: -6px;
        right: -18px;
        width: 16px;
      }
    }

    .left-wrap {
      display: flex;

      .logo {
        margin-right: 50px;
        color: #fff;
        font-weight: bold;
        font-size: 18px;

        // img {
        //   width: 180px;
        //   height: auto;
        // }
      }
    }

    .user-wrap {
      padding: 0 12px;
      cursor: pointer;

      .user-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .username {
        color: #fff;
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }

  .content-wrap {
    padding: 24px;
  }
}
