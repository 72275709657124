.chat-window {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  height: 100%;
  min-height: 400px;

  .window-top {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #eee;

    .name {
      flex-grow: 1;
      padding: 0 30px;
      font-weight: bold;
      font-size: 16px;
    }

    .vip-wrap {
      flex-shrink: 0;
      padding-right: 80px;
      text-align: center;
    }
  }

  .message-wrap {
    flex-grow: 1;
    overflow-y: auto;
  }

  .message-input {
    flex-shrink: 0;
    border-top: 1px solid #eee;

    .input-wrap {
      // height: 150px;
      .ant-input {
        border: none;
        box-shadow: none;
      }

      .control-wrap {
        display: flex;
        justify-content: space-between;
        padding: 15px 30px;

        .quick-reply {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }

    .quick-wrap {
      box-sizing: border-box;
      padding-bottom: 10px;
      overflow: hidden;
      transition: height 0.3s;

      &.show-quick {
        height: 169px;
      }

      &.hidden-quick {
        height: 0;
        padding: 0;
      }

      .ant-table-row {
        cursor: pointer;
      }
    }
  }
}
