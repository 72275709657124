.domain-test-modal {
  .test-btn {
    text-align: center;
    margin: 20px 0;
  }
  .tips{
    text-align: center;
  }
}
.domain-test-result {
  .ant-modal-confirm-title {
    text-align: center;
    font-size: 16px !important;
    font-weight: bold !important;
  }
  .domain-health-status {
    font-size: 16px;
    color: green;
    text-align: center;
  }
  .domain-error-status {
    font-size: 16px;
    color: red;
    text-align: center;
  }
  .domain-test-time {
    text-align: center;
  }
}
