.approval-pass-modal {
  .top-content {
    margin-bottom: 15px;
  }

  .ant-modal-body {
    max-height: 600px;
    overflow: auto;
  }
}
