.film-modal {
  width: 800px;

  .ant-modal-body {
    padding-top: 10px;
  }

  .player-content {
    width: 100%;
    height: 450px;
  }
}
