.not-found {
  box-sizing: border-box;
  height: 100vh;
  padding-top: 100px;
  text-align: center;
  background-color: #f0f2f5;

  .title {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    line-height: 1.8;
  }

  .sub-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.6;
  }

  .back {
    margin-top: 24px;
  }
}
