.long-video-approval {
  .table-title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;
  }

  .batch-btn-wrap {
    margin-bottom: 10px;
  }

  .des {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-height: 37px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
