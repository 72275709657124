.verify-wrap {
  position: relative;
  margin-bottom: 24px;

  .sliderContainer {
    height: 14px;
    background-color: #fdfbfb;

    .sliderMask {
      top: -8px;
      height: 30px;
      background: none;
      border: none;

      .slider {
        width: 48px;
        height: 30px;
        background: url('./images//slider.png');
        border: none;
        border-radius: 15px;

        .sliderIcon {
          display: none;
        }
      }
    }
  }

  .success-wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160px;
    background-color: rgba(255, 255, 255, 0.6);

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }

    .success-text {
      color: rgb(35, 211, 0);
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
