.film-content-wrap {
  .film-card {
    display: flex;
    // padding: 0 24px;

    .img-wrap {
      position: relative;
      margin-right: 20px;

      .film-img {
        flex-shrink: 0;
        width: 169px;
        height: 96px;
      }

      .film-status {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0;
      }
    }

    .film-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;

      .title {
        overflow: hidden;
        font-weight: bold;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .film-id {
        font-size: 12px;

        span {
          color: #008dff;
          cursor: pointer;
        }
      }

      .film-label,
      .film-num {
        font-size: 12px;

        span {
          margin-right: 20px;
        }
      }
    }

    .film-btn {
      flex-shrink: 0;
      justify-content: center;
      margin-left: 20px;
    }
  }

  .drag-icon {
    display: flex;
    align-items: center;
    margin: 0 10px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;

    .anticon {
      cursor: grab;
    }
  }
}
