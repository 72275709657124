.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.image-cell-wrap {
  text-align: left;

  .edit {
    display: none;

    &.uploading {
      display: inline-block;
    }
  }
}

.editable-row {
  .ant-input,
  .ant-input-number,
  .ant-select {
    font-size: 12px;
  }

  .ant-picker-input > input {
    font-size: 12px;
  }
}

.editable-row:hover {
  .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .editable-cell-no-hover {
    padding: 5px 12px;
    border: none;
  }

  .image-cell-wrap {
    .edit {
      display: inline-block;
    }
  }
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ellipsis-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis-row {
  display: -webkit-box;
  // height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  // -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table-cell-img {
  width: 80%;
  height: auto;
}

.edit-cell-option {
  font-size: 12px !important;
}
