.data-kanban-wrap {
  width: 100%;
  padding: 24px;
  line-height: 0;

  .ant-space-vertical {
    width: 100%;
  }

  .time-label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    cursor: pointer;

    &.selected,
    &:hover {
      color: #1890ff;
    }
  }

  .radio-wrap {
    margin-bottom: 10px;
    text-align: center;
  }
}
