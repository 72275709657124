.cut-config-wrap {
  .cut-config-content {
    padding: 24px;
  }
}

.add-cut-modal {
  .tips-title {
    padding-left: 10px;
    border-left: 3px solid #1990ff;
  }

  .tips {
    margin-bottom: 8px;
    padding-left: 13px;
    color: red;
    font-size: 12px;
  }
}
