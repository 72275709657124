.user-avatars {
  display: flex;

  .avatar-item {
    margin-right: 15px;
    span {
      margin-left: 5px;
    }
  }
}
