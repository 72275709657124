.custom-service-chat {
  .ant-modal-body {
    height: 714px;
    padding: 0;
  }

  .chat-wrap {
    display: flex;
    height: 100%;

    .chat-list {
      flex-shrink: 0;
      // width: 250px;
      width: 21%;
      border-right: 1px solid #eee;

      .top-search {
        padding: 15px 10px;
      }

      .status-wrap {
        display: flex;
        justify-content: center;
        margin: 10px 0;
      }

      .contact-list {
        height: 600px;
        overflow-y: auto;
      }

      .loading-more {
        text-align: center;

        span {
          margin-left: 8px;
          font-size: 12px;
        }
      }

      .no-more {
        font-size: 12px;
        text-align: center;
      }
    }

    .chat-window-wrap {
      flex-grow: 1;
      // width: 750px;
      width: 62%;
      height: 100%;

      &.grow-window {
        // width: 950px;
        width: 76.5%;
      }
    }

    .user-detail-wrap {
      flex-shrink: 0;
      width: 17%;
      height: 100%;
      border-left: 1px solid #eee;
      transition: width 0.15s;

      &.hidden {
        width: 2.5%;
        height: calc(100% - 59px);
        margin-top: 59px;
        border-top: 1px solid #eee;
        border-left: none;
      }
    }
  }
}
