.long-video-detail-modal {
  .detail-top-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .film-componey-info {
      display: flex;

      .logo {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        background-color: #eee;
        border-radius: 50%;
      }

      .info-wrap {
        .name {
          display: flex;
          align-items: center;
          font-weight: bold;
          font-size: 16px;
        }

        .level {
          font-size: 12px;

          span {
            margin-left: 15px;
          }
        }

        .count {
          margin-top: 20px;
          font-size: 12px;

          span {
            margin-right: 15px;
          }
        }
      }
    }

    .play-btns {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .ant-btn {
        margin-bottom: 15px;
      }
    }
  }

  .ant-modal-body {
    max-height: 600px;
    overflow: auto;
  }
}
