.user-management {
  .content-wrap {
    padding: 24px;

    .table-title {
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.move-account-tips {
  width: 200px;
  margin-top: 5px;
  color: red;
  font-size: 12px;
}
