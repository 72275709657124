.login-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f0f2f5;
  background-image: url('../../images/login-bg.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;

  .login-content {
    width: 328px;

    .btn-wrap {
      text-align: center;
    }

    .code-img {
      width: 120px;
      height: 40px;
      cursor: pointer;
    }
  }
}
