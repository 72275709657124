.chat-user-detail {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  .user-avater {
    padding: 40px 0 10px;
  }

  .nickname {
    overflow: hidden;
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .invite-code {
    margin-bottom: 80px;
    color: #7f7f7f;
    font-size: 14px;
  }

  .vip-level,
  .max-times,
  .rest-times {
    font-size: 16px;
  }

  .max-times {
    margin-top: 120px;
  }

  .arrow {
    position: absolute;
    top: 50%;
    left: 10px;
    padding: 10px 10px 10px 0;
    font-size: 16px;
    transform: translateY(-50%);
    cursor: pointer;

    &.fold {
      left: 50%;
      margin-top: -30px;
      transform: translate(-50%, -50%);
    }
  }
}
