.work-order {
  .ant-page-header-heading-title {
    margin-right: 50px;
    overflow: visible;
  }
  .title{
    font-weight: 600;
    font-size: 20px;
  }
}
