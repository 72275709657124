.ant-page-header {
  padding: 8px 24px !important;
}

.edit-table {
  .ant-table-thead {
    & > tr {
      & > th {
        padding: 16px 8px;
        font-size: 12px;
      }
    }
  }

  .ant-table-tbody {
    & > tr {
      & > td {
        padding: 5px 8px;
        font-size: 12px;
      }
    }
  }
}
