.filming-management {
  .content-wrap {
    padding: 24px;

    .filming-content-wrap {
      // margin: 24px;
      padding: 24px;
      background-color: #fff;

      .calender-header {
        margin-bottom: 24px;

        .total {
          padding-left: 50px;
          font-size: 14px;

          span {
            color: #008dff;
          }
        }
      }

      .film-count {
        color: #008dff;
        font-weight: bold;
        line-height: 30px;
        text-align: center;
      }

      .ant-picker-calendar-full {
        .ant-picker-panel {
          .ant-picker-body {
            th {
              text-align: center;
            }
          }

          .ant-picker-calendar-date-content {
            height: 40px;
          }
        }
      }

      .arrow-wrap {
        color: #5cc9f3;
        text-align: center;
      }
    }
  }
}
